import axios from "axios";
import customInput from "../../components/custom-input.vue";
import customTextarea from "../../components/custom-textarea.vue";
import vClickOutside from "v-click-outside";

export default {
  name: "page",
  props: ["app_url"],
  data() {
    return {
      side_menu_shown: false,
      slide: false,
      is_logged_in: true
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mounted() {},

  computed: {},
  methods: {
    onClickOutsideOfMenu(event) {
      if (
        this.side_menu_shown &&
        this.slide &&
        !event.target.classList.contains("main-nav-menu")
      ) {
        this.closeNav();
      }
    },
    go_to_public() {},
    go_to_settings() {
      window.location = window.app.app_url + "settings";
    },
    logout_user() {},
    openNav() {
      this.slide = !this.slide;
      setTimeout(() => {
        this.side_menu_shown = !this.side_menu_shown;
      }, 200);
    },
    closeNav() {
      this.slide = false;
      this.side_menu_shown = false;
    }
  }
};
