import axios from "axios";
import customInput from "../../components/custom-input.vue";
import customTextarea from "../../components/custom-textarea.vue";
import vClickOutside from "v-click-outside";

export default {
  name: "landing",
  components: { customInput, customTextarea },
  props: ["app_url"],
  data() {
    return {
      active_plan: null,
      errors: [],
      menu_open: false,
      registerLoading: false,
      contact_user: {
        name: null,
        message: null,
        email: null
      },
      user: {
        email: null
      },

      contact_success: false,
      contact_error: false,
      contact_errors: []
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mounted() {},
  computed: {},
  methods: {
    encodeLink(str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return "%" + c.charCodeAt(0).toString(16);
      });
    },

    see_terms() {
      window.location = this.app_url + "privacy-policy";
    },
    go_to_login() {
      if (this.user.email && this.validate_email(this.user.email)) {
        window.location =
          this.app_url + "login?&email=" + this.encodeLink(this.user.email);
      } else {
        window.location = this.app_url + "login";
      }
    },
    go_to_signup() {
      if (this.user.email && this.validate_email(this.user.email)) {
        window.location =
          this.app_url + "signup?&e=" + this.encodeLink(this.user.email);
      } else {
        window.location = this.app_url + "signup";
      }
    },
    away() {
      this.menu_open = false;
    },
    package_chosen(package_type) {
      this.active_plan = package_type;
      if (this.user.email && window.validate_email(this.user.email)) {
        window.location =
          this.app_url +
          "signup?&e=" +
          this.encodeLink(this.user.email) +
          "&trial=1";
      } else {
        window.location = this.app_url + "signup?&trial=1";
      }
    },
    validate_email(email) {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return email != null && email && re.test(email);
    },
    go_to_section(e) {
      document.location = "/" + e.target.hash;
      this.away();
      return false;
    },
    go_to_landing() {
      window.scrollTo(0, 0);
      //window.location.reload(false);
    },
    send_contact() {
      this.contact_error = false;
      this.contact_errors = [];
      if (!this.contact_user.email) {
        this.contact_errors.push("email");
      }
      if (
        this.contact_user.email &&
        !this.validate_email(this.contact_user.email)
      ) {
        if (!this.contact_errors.includes("email")) {
          this.contact_errors.push("email");
        }
      }
      if (this.contact_errors && this.contact_errors.length === 0) {
        let args = {
          email: this.contact_user.email,
          name: this.contact_user.name,
          message: this.contact_user.message
        };
        axios
          .post(
            window.app.site_url +
              "/wp-admin/admin-ajax.php?action=save_user_contact",
            args
          )
          .then(resp => {
            this.contact_user.email = null;
            this.contact_user.name = null;
            this.contact_user.message = null;
            this.contact_success = true;
          });
      }
    },
    toggleMenu() {
      this.menu_open = !this.menu_open;
    },
    register_click() {
      this.errors = [];
      if (!this.user.email) {
        this.errors.push("email");
      }
      if (this.user.email && !this.validate_email(this.user.email)) {
        if (!this.errors.includes("email")) {
          this.errors.push("email");
        }
      }
      if (this.errors && this.errors.length === 0) {
        this.registerLoading = true;
        setTimeout(() => {
          this.registerLoading = false;
          if (!this.active_plan) {
            window.location = this.app_url + "signup?&e=" + this.user.email;
          } else {
            window.location =
              this.app_url + "signup?&e=" + this.user.email + "&trial=1";
          }
        }, 200);
      }
    },
    set_value(args) {
      this.user[args.prop_name] = args.prop_value;
    },
    set_contact_value(args) {
      this.contact_user[args.prop_name] = args.prop_value;
    }
  },
  created() {}
};
