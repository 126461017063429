import "@babel/polyfill";
import "es6-promise/auto";
import Vue from "vue";
import LandingPage from "./pages/home/landing-page";
import DefaultPage from "./pages/page/page";

if (document.getElementById("vueapp")) {
  var vm = new Vue({
    el: "#vueapp",
    components: {
      LandingPage,
      DefaultPage
    }
  });
}

global.vm = vm;
