var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.focus_out_global,
          expression: "focus_out_global"
        }
      ],
      class: {
        "form-group": true,
        "has-input": _vm.focus || _vm.value,
        "is-focused": _vm.show_hint,
        required: _vm.required,
        "has-icon": !!_vm.icon_class,
        unit: !!_vm.unit,
        disabled: _vm.disabled,
        "timepicker-container": !!_vm.timepicker,
        "has-error": _vm.invalid,
        "reset-visible": !!_vm.show_reset,
        active: _vm.has_clicked
      }
    },
    [
      _c("div", { staticClass: "placeholder show" }, [
        _c("label", { attrs: { for: _vm.name } }, [_vm._v(_vm._s(_vm.label))]),
        _vm._v(" "),
        _vm.required
          ? _c("span", { staticClass: "star" }, [_vm._v("*")])
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.datepicker && !_vm.timepicker
        ? _c("input", {
            class: { "form-input": true, selectized: !!_vm.autosuggest },
            attrs: {
              maxLength: _vm.limit,
              autofocus: !!_vm.autofocus,
              min: _vm.min,
              oninput:
                "javascript: if (this.maxLength && this.maxLength > 0 && this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
              type: _vm.input_type,
              autocomplete: "off",
              id: _vm.name
            },
            domProps: { value: _vm.value },
            on: {
              blur: _vm.focus_out,
              focus: _vm.on_focus,
              keydown: [
                function($event) {
                  return _vm.validateInput($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.on_enter($event.target.value)
                }
              ],
              keyup: function($event) {
                return _vm.update_self($event)
              },
              paste: function($event) {
                return _vm.validatePastedInput($event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.unit
        ? _c("span", { staticClass: "form-unit" }, [
            _vm._v("\n    " + _vm._s(_vm.unit) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.icon_class
        ? _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.name !== "password",
                expression: "name !== 'password'"
              }
            ],
            class: _vm.icon_class
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show_reset && _vm.value
        ? _c("span", {
            staticClass: "icon icon-reset",
            on: { click: _vm.reset }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.name === "password"
        ? _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.icon_class,
                expression: "icon_class"
              }
            ],
            class: _vm.icon_class,
            on: { click: _vm.toggle_psw_visibility }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.error_msg
        ? _c("div", { staticClass: "form-error" }, [
            _vm._v(_vm._s(_vm.error_msg))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.focus && _vm.hint
        ? _c("div", { staticClass: "hint" }, [_vm._v(_vm._s(_vm.hint))])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }