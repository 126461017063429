var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "form-group textarea": true,
        "has-input": _vm.focus || _vm.value,
        "is-focused": _vm.show_hint,
        required: _vm.required,
        "has-error": _vm.invalid
      }
    },
    [
      _c("div", { staticClass: "placeholder show" }, [
        _c("label", { attrs: { for: _vm.name } }, [_vm._v(_vm._s(_vm.label))]),
        _vm._v(" "),
        _vm.required
          ? _c("span", { staticClass: "star" }, [_vm._v("*")])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("textarea", {
        staticClass: "form-input textarea",
        attrs: { maxlength: _vm.limit, id: _vm.name },
        domProps: { value: _vm.value },
        on: {
          blur: _vm.focus_out,
          input: _vm.on_input,
          focus: _vm.on_focus,
          keyup: function($event) {
            return _vm.update_self($event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _vm.error_msg
        ? _c("div", { staticClass: "form-error" }, [
            _vm._v(_vm._s(_vm.error_msg))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.focus && _vm.hint
        ? _c("div", { staticClass: "hint" }, [_vm._v(_vm._s(_vm.hint))])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }