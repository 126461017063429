<template>
  <div
    :class="{
      'form-group textarea': true,
      'has-input': focus || value,
      'is-focused': show_hint,
      required: required,
      'has-error': invalid
    }"
  >
    <div class="placeholder show">
      <label :for="name">{{ label }}</label>
      <span v-if="required" class="star">*</span>
    </div>
    <textarea
      :maxlength="limit"
      @blur="focus_out"
      @input="on_input"
      @focus="on_focus"
      :value="value"
      @keyup="update_self($event.target.value)"
      :id="name"
      class="form-input textarea"
    >
    </textarea>
    <div v-if="error_msg" class="form-error">{{ error_msg }}</div>
    <div v-if="focus && hint" class="hint">{{ hint }}</div>
  </div>
</template>

<script>
export default {
  name: "custom-textarea",
  props: [
    "limit",
    "value",
    "required",
    "error_msg",
    "label",
    "name",
    "invalid",
    "capitalize",
    "punctuation_capitalization",
    "hint"
  ],
  data() {
    return {
      show_hint: false,
      focus: false
    };
  },
  computed: {},
  methods: {
    on_focus() {
      if (this.hint) {
        this.show_hint = true;
      }
      this.focus = true;
    },
    on_input() {
      this.$emit("input");
    },
    focus_out() {
      this.show_hint = false;
      if (!this.value) {
        this.focus = false;
      }
    },
    update_self(value) {
      if (this.capitalize) {
        value = this.capialize_inp(value);
      }
      if (this.punctuation_capitalization) {
        value = this.capitalize_after_punctuation_mark(value);
      }
      this.$emit("change", { prop_name: this.name, prop_value: value });
    },
    capitalize_after_punctuation_mark(input) {
      input = input === undefined || input === null ? "" : input;
      return input
        .toString()
        .replace(/(^|[\.?\?\!] *)([a-zäöü])/g, function(
          match,
          separator,
          char
        ) {
          return separator + char.toUpperCase();
        });
    },
    capialize_inp(text) {
      let i,
        words,
        w,
        result = "";
      text = text.replace(/^\s+/, "");
      words = text.split(" ");
      for (i = 0; i < words.length; i += 1) {
        w = words[i];
        if (w[0] && w[0] == w[0].toUpperCase()) {
          result += w.substr(0, 1).toUpperCase() + w.substr(1);
        } else {
          result += w.substr(0, 1).toLowerCase() + w.substr(1);
        }
        if (i < words.length - 1) {
          result += " "; // Add the spaces back in after splitting
        }
      }
      return result;
    }
  }
};
</script>
<style scoped>
.textarea {
  position: relative;
}

.textarea:before {
  position: absolute;
  content: "";
  display: block;
  width: 98%;
  height: 30px;
  background: #fff;
  z-index: 1;
  top: 0.2rem;
  left: 0.3rem;
}
</style>
