<template>
  <div
    v-click-outside="focus_out_global"
    :class="{
      'form-group': true,
      'has-input': focus || value,
      'is-focused': show_hint,
      required: required,
      'has-icon': !!icon_class,
      unit: !!unit,
      disabled: disabled,
      'timepicker-container': !!timepicker,
      'has-error': invalid,
      'reset-visible': !!show_reset,
      active: has_clicked
    }"
  >
    <div class="placeholder show">
      <label :for="name">{{ label }}</label>
      <span v-if="required" class="star">*</span>
    </div>

    <input
      :maxLength="limit"
      @blur="focus_out"
      @focus="on_focus"
      :autofocus="!!autofocus"
      v-if="!datepicker && !timepicker"
      :value="value"
      :min="min"
      oninput="javascript: if (this.maxLength && this.maxLength > 0 && this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      :type="input_type"
      @keydown="validateInput($event)"
      @keyup="update_self($event)"
      @paste="validatePastedInput($event)"
      autocomplete="off"
      @keydown.enter="on_enter($event.target.value)"
      :class="{ 'form-input': true, selectized: !!autosuggest }"
      :id="name"
    />

    <span class="form-unit" v-if="unit">
      {{ unit }}
    </span>
    <span
      v-show="name !== 'password'"
      v-if="icon_class"
      :class="icon_class"
    ></span>
    <span
      @click="reset"
      v-if="show_reset && value"
      class="icon icon-reset"
      style=""
    ></span>

    <span
      @click="toggle_psw_visibility"
      v-if="name === 'password'"
      v-show="icon_class"
      :class="icon_class"
    ></span>

    <div v-if="error_msg" class="form-error">{{ error_msg }}</div>
    <div v-if="focus && hint" class="hint">{{ hint }}</div>
  </div>
</template>
<script>
import vClickOutside from "v-click-outside";

export default {
  name: "custom-input",
  props: [
    "value",
    "disabled",
    "autosuggest",
    "required",
    "error_msg",
    "label",
    "min",
    "format",
    "show_reset",
    "hint",
    "capitalize",
    "name_capitalization",
    "name",
    "limit",
    "autofocus",
    "icon_class",
    "input_type",
    "invalid",
    "allow_input",
    "datepicker",
    "unit",
    "timepicker"
  ],
  components: {},

  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    let readonly = true;
    if (this.allow_input) {
      readonly = true;
    }
    return {
      readonly: readonly,
      searchResults: [],
      show_hint: false,
      hide_pop: false,
      date: null,
      displayTime: "00:00",
      has_clicked: false,
      focus: false
    };
  },

  computed: {},

  mounted() {},
  methods: {
    capialize_inp(text) {
      return text.charAt(0).toUpperCase() + text.substring(1);
    },
    on_focus() {
      if (this.hint) {
        this.show_hint = true;
      }
      this.focus = true;
    },
    focus_out($evt) {
      if (this.hint) {
        this.show_hint = false;
      }
      if (!this.value) {
        this.focus = false;
      }
    },
    focus_out_global() {
      if (this.hint) {
        this.show_hint = false;
      }
      if (!this.value) {
        this.focus = false;
      }
    },
    isNumberEntered($event) {
      let keyCode = $event.which ? $event.which : $event.keyCode;
      let cmd_held = $event.metaKey;
      let ctrl_held = $event.ctrlKey;
      let shift_held = $event.shiftKey;
      if (shift_held) return false;
      if (
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        keyCode === 46 ||
        keyCode === 8 ||
        ((cmd_held || ctrl_held) && [65, 67, 86].includes(keyCode))
      ) {
        return true;
      }
      return false;
    },
    pop_hide(e) {
      this.focus_out();
      this.has_clicked = false;
    },
    clicked() {
      this.focus = true;
      this.has_clicked = true;
    },

    validateInput($event) {
      if (this.name === "phone") {
        if (!this.isPhoneEntered($event)) {
          $event.preventDefault();
          return false;
        }
      }
    },
    capitalize_name(input) {
      input = input === undefined || input === null ? "" : input;
      input = input.toLowerCase();
      return input
        .toString()
        .replace(/(^|[\ ?,\-] *)([a-zõäöü])/g, function(
          match,
          separator,
          char
        ) {
          return separator + char.toUpperCase();
        });
    },
    isPhoneEntered($event) {
      let keyCode = $event.which ? $event.which : $event.keyCode;
      let cmd_held = $event.metaKey;
      let ctrl_held = $event.ctrlKey;
      if (
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        keyCode === 46 ||
        keyCode === 187 ||
        keyCode === 107 ||
        keyCode === 8 ||
        ((cmd_held || ctrl_held) && [65, 67, 86].includes(keyCode))
      ) {
        return true;
      }
      return false;
    },

    update_self($event) {
      let value = $event.target.value.replace(/^\s+/, "");
      if (this.capitalize) {
        value = this.capialize_inp(value);
      }
      if (this.name_capitalization) {
        value = this.capitalize_name(value);
      }
      this.$emit("change", { prop_name: this.name, prop_value: value });
    },
    reset() {
      this.$emit("change", { prop_name: this.name, prop_value: null });
    },
    on_enter(name) {
      this.$emit("enter", name);
    }
  }
};
</script>

<style scoped>
.datepicker-input {
  cursor: pointer;
}

.form-group.has-input.reset-visible.has-icon span.icon-calendar {
  display: none;
}

.icon-calendar {
  pointer-events: none;
}

.results {
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-direction: column;
  max-height: 20rem;
  overflow: auto;
  background: white;
  top: 5.5rem;
  width: 99.4%;
  border: 0.2rem solid #00a8b3;
  box-shadow: 0 0 0.8rem rgba(0, 168, 178, 0.5);
  border-radius: 0.4rem;
  margin-left: -0.1rem;
}

.results .result-item:first-child:before {
  content: "";
  height: 0.7rem;
  width: 100%;
  position: absolute;
  top: -0.7rem;
  background: inherit;
  z-index: 2;
  left: 0rem;
}

.result-item {
  margin: 1rem 0;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 1rem 1.2rem;
  letter-spacing: 0.03em;
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-wrap: wrap;
}

.result-item span,
.result-item div {
  pointer-events: none;
}
</style>
